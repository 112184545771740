const leaflets = {
    "HTSMed": {
        "config": {
            "primary-color": "rgb(0, 64, 112)",
            "secondary-color": "rgb(0, 135, 193)",
            "tertiary-color": "rgb(99, 44, 56)",
            "hover-color": "rgba(220, 35, 17, 0.5)",
            "clientIcon": require("assets/images/logo-hts-med.png"),
            "serverPath": "demo1.volantinointerattivo.net/",
            "pdfPath": "/media/pdf/catalogo.pdf",
            "shareFlyerTitle": "Dai uno sguardo al nuovo catalogo interattivo di HTSMed!",
            "shareFlyerURL": "https://demo1.volantinointerattivo.net?v=308",
            "shareProductSentence": "Dai uno sguardo al nostro Catalogo.",
            "ogTitleMeta": "HTSMED | Catalogo Interattivo",
            "ogDescriptionMeta": "Dai uno sguardo al nostro Catalogo.",
            "ogImageMeta": "https://demo1.volantinointerattivo.net/media/images/htsmed_fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-",
            "release_id": "1",
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 10,
            "client_id": 3,
            "signboard_id": 3,
            "has_pages": true,
            "hide_grocery_list": true,
            "hide_searchbar": false,
            "line_through": false,
            "category_banner": "",
            "category_banner_mobile": "",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "type": "catalog",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "add_icon_list": true
        },
        "leaflet": {
            "name": "Demo HTS Med",
            "code": "308",
            "id_campaign": "308",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/pages/indice-hts_aD2PjPW_Ak7UvNk.png",
                "links": [
                    {
                        "page": 1,
                        "blueprint": {
                            "top": 28.07542264874438,
                            "left": 13.194942179747505,
                            "width": 74.27571388965292,
                            "height": 6.379577652487008
                        }
                    },
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 34.60004668059327,
                            "left": 12.948579606946915,
                            "width": 74.27571388965292,
                            "height": 6.379577652487008
                        }
                    },
                    {
                        "page": 3,
                        "blueprint": {
                            "top": 41.138131566669394,
                            "left": 13.1843704017729,
                            "width": 74.27571388965292,
                            "height": 6.379577652487008
                        }
                    },
                    {
                        "page": -1,
                        "type": "url",
                        "url": "https://www.htsmed.com/contatti",
                        "target": "_blank",
                        "blueprint": {
                            "top": 67.01209417178774,
                            "left": 13.151499118165786,
                            "width": 73.84664902998234,
                            "height": 9.150414831027676
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Macchinari diagnostici",
                    "subcategories": [
                        "Serie MRI",
                        "Serie CT"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/pages/page_1_4UKjvAD.jpg",
                    "products": []
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/pages/page_2_iAp5nXY.jpg",
                    "products": [
                        {
                            "item_id": "cstm59176977",
                            "field1": "Anke",
                            "field2": "Supermark 1.5t",
                            "field3": "Sistema MRI superconduttivo",
                            "field4": "",
                            "description": "Anke Supermark 1.5t Sistema MRI superconduttivo",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": true,
                            "underpriced_product": false,
                            "category": "Macchinari diagnostici",
                            "subcategory": "Serie MRI",
                            "blueprint": {
                                "top": 19.14657080204296,
                                "left": 19.977172788038974,
                                "width": 69.50497208276836,
                                "height": 30.21334599668255
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/Unknown_to14Ccw.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/cropped_image_JOia7jm_rrhPl3J.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "Sfoglia la brochure",
                                    "data": "https://www.htsmed.com/wp-content/uploads/2020/11/supermark_1.5_brochure.pdf"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm08847949",
                            "field1": "Anke ",
                            "field2": "openmark 5000",
                            "field3": "Sistema MRi",
                            "field4": "",
                            "description": "Anke  openmark 5000 Sistema MRi",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": true,
                            "underpriced_product": false,
                            "category": "Macchinari diagnostici",
                            "subcategory": "Serie MRI",
                            "blueprint": {
                                "top": 55.61874087364072,
                                "left": 12.392323551183454,
                                "width": 69.50497208276836,
                                "height": 30.21334599668255
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/MRI-Openmark-5000-permanent-System....-570x570_o2Xoa61.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/Unknown-1_6MYaJGw.jpeg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/cropped_image_HMVBV1U_4LlYxlR.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "Anke OPenmark 5000",
                                    "data": {
                                        "titolo": "Risonanza Magnetica Aperta 0.51T",
                                        "testo": "La nuova generazione di sistemi RM a Medio Campo.\nOpenmark 5000 è l’unica Risonanza Magnetica Permanente da 0.51T che garantisce Neuroimaging di altissima qualità diagnostica.\nIl design del nuovo magnete riduce al minimo l’ansia e la claustrofobia dei pazienti\n-Sistema RF Digitale a 4 Canali\n-Bobine Phased Array 4CH dedicate\n-Elevata omogeneità e stabilità di campo magnetico\n-Imaging di elevata qualità, con costi di gestione estremamente ridotti.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/images/MRI-Openmark-5000-permanent-System....-570x570.jpg"
                                        ]
                                    }
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://drive.google.com/file/d/18EA7VzuAP0zon6hCixJ90Zni0IjW7jSv/view?usp=sharing"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/pages/page_3_c3M8a9A.jpg",
                    "products": [
                        {
                            "item_id": "cstm40703419",
                            "field1": "Anke ",
                            "field2": "Anatom 128",
                            "field3": "",
                            "field4": "",
                            "description": "Anke  Anatom 128",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": true,
                            "underpriced_product": false,
                            "category": "Macchinari diagnostici",
                            "subcategory": "Serie CT",
                            "blueprint": {
                                "top": 18.532352628223247,
                                "left": 17.05626933875181,
                                "width": 69.50497208276836,
                                "height": 30.21334599668255
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/411281_searchImg_0_sizeWidth_xesD4LD.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/70042-16038960_9ZVsIMM.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/cropped_image_m4Xxk0d_wfNffX3.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "Specifiche Anatom 128",
                                    "data": {
                                        "specifiche": "Anello anti-contatto e anti-abrasione, Detettore OptiWawe(TM) ad alta efficienza, Tecnologia ADMIR per ricostruzione iterativa, Piattaforma di gestione dosaggio ADOSE, Immagini cardiache facili e a basso dosaggio, Screening polmoni a dosaggio ultra-basso ",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/images/70042-16038960.jpg"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm47492338",
                            "field1": "Anke",
                            "field2": "Anatom 64 clarity / Anatom 64 precision",
                            "field3": "",
                            "field4": "",
                            "description": "Anke Anatom 64 clarity / Anatom 64 precision",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [
                                "Anatom 64 clarity",
                                "Anatom 64 precision"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": true,
                            "underpriced_product": false,
                            "category": "Macchinari diagnostici",
                            "subcategory": "Serie CT",
                            "blueprint": {
                                "top": 57.82364377885107,
                                "left": 10.488050447125014,
                                "width": 73.03438469325197,
                                "height": 31.937118648558624
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/anke_clarity_AUyNqgA.png",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/70042-15996059_Uw19R4t.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/products/cropped_image_jVgTDf6_vfqQCwk.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/video/Anke_company_introduction_-Who_Released_Chinas_First_MRI_System.mp4"
                                },
                                {
                                    "type": "info",
                                    "title": "Innovativo sistema TC 64 Slices",
                                    "data": {
                                        "titolo": "Anatom 64 Precision",
                                        "testo": "Le innovazioni tecnologiche del sistema TC Anke Anatom 64 Precision assicurano molti vantaggi: i principali sono una migliore definizione delle immagini in un tempo di acquisizione inferiore e, soprattutto, una marcata riduzione della dose.\nIl sistema, è in grado di bilanciare la dose di raggi x erogata al paziente sulla base del volume e del distretto anatomico da studiare, garantendo una riduzione della dose fino al 40%.\nOltre che per le tradizionali applicazioni cliniche, la TC Anatom 64 Precision, è particolarmente indicata per lo studio del cuore e di tutto il sistema cardiovascolare.\nL’estrema velocità di esecuzione dell’esame oltre la netta riduzione della dose, rendono questo sistema TC particolarmente adatto anche ai pazienti pediatrici.",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/images/70042-15996059.jpg"
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/kRtSiM9FXj/pages/fine-hts_gllmHyZ.png",
                    "products": []
                }
            ]
        }
    }

}

const lefletSwitch = () => {
    return leaflets.HTSMed;
}

export const serverResponse = lefletSwitch(); 