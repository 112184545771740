import React, { Component } from 'react';
//icon
import Logo from '../../../assets/images/icons/plus.svg';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    Col,
    Tooltip
} from 'reactstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import actions from '../../../redux/groceryList/action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlusCircle,
    faMinusCircle,
    faBars,
    faAngleLeft,
    faAngleRight,
} from '@fortawesome/free-solid-svg-icons';

import HatChefIcon from '../../../assets/images/icons/hatchef.svg';
import SpecsIcon from '../../../assets/images/icons/specs.svg';
import PromoIcon from '../../../assets/images/icons/promo.svg';
import InfoIcon from '../../../assets/images/icons/info.svg';
import WorldIcon from '../../../assets/images/icons/world.svg';
import PlayIcon from '../../../assets/images/icons/play.svg';

import { NotificationManager } from 'react-notifications';

import { Textfit } from 'react-textfit';

import '../../../assets/css/custom.css';

import AddProd from '../../../assets/images/icons/white_add_prod.png';
import Facebook from '../../../assets/images/icons/facebook.svg';
import Whatsapp from '../../../assets/images/icons/whatsapp.svg';
import { serverResponse } from '../../../util/fakeServer';

import { strings } from '../../../util/data/strings';
import RelatedProduct from '../RelatedProduct';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { isMobile, isIOS } from 'react-device-detect';
import ReactGA from 'react-ga';
import { gaEventLabel } from '../../../settings/gaConfig';

const productUrl = serverResponse.config.shareFlyerURL.substr(0, serverResponse.config.shareFlyerURL.indexOf('?'));

class PlusMarker extends Component {

    setModal = (swipe) => {
        //TODO
        //axios per l'incremento del counter di visualizzazioni della modal plus
        /*
        if(this.state.modal === false){
            axios({
            method: 'post',
            url: '',
            headers: {
            },
        })
            .then(res => {
 
            }).catch(error => {
                //console.log(error);
            });
        }*/
        this.props.setSwipe(swipe);
        //this.props.leafletSwipe();
        this.setState({
            modal: !this.state.modal,
            quantity: (this.props.info.quantity_step ? this.props.info.quantity_step : 1),
            varietySelected: (this.props.info.varieties ? this.props.info.varieties[0] : "")
        });
    }

    state = {
        modal: false,
        quantity: (this.props.info.quantity_step ? this.props.info.quantity_step : 1),
        varietySelected: (this.props.info.varieties ? this.props.info.varieties[0] : ""),
        saveTooltipOpen: false,
        products: [],
    }

    componentDidMount() {
        if (serverResponse.config.ga_active) {
            ReactGA.initialize(serverResponse.config.ga_tracking_id, {
    gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=none' },
  })
        }
    }

    componentWillMount = () => {
        let tmpProducts = []
        serverResponse.leaflet.pages.forEach((page) => {
            tmpProducts = tmpProducts.concat(page.products);
        });
        this.setState({
            products: tmpProducts,
        });
    }

    incrementQty = () => {
        this.setState({ quantity: this.state.quantity + (this.props.info.quantity_step ? this.props.info.quantity_step : 1) });
    }

    decrementQty = () => {
        if (this.props.info.quantity_step) {
            if (this.state.quantity > this.props.info.quantity_step) {
                this.setState({ quantity: this.state.quantity - (this.props.info.quantity_step) });
            }
        } else if (this.state.quantity > 1) {
            this.setState({ quantity: this.state.quantity - 1 });
        }
    }

    handleChangeVariety = (e) => {
        this.setState({
            varietySelected: e.target.value
        })
    }

    handleAddProduct = () => {
        let obj = Object.assign({}, this.props.info);
        obj.quantity = this.state.quantity;
        obj.done = false;
        obj.variety = this.state.varietySelected;
        this.props.addProduct(obj);
        NotificationManager.success('Controlla la tua lista della spesa', 'Prodotto aggiunto', 800);
    }

    shareOnFacebook = () => {
        if (serverResponse.config.ga_active) {
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.share_product_facebook,
                label: String(this.props.info.item_id)
            });
        }
        // let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        // window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        let fb_share_url = "https://www.facebook.com/share.php?u=";
        let page_url = productUrl + "/share/product.php?c=" + this.props.info.item_id;
        window.open(fb_share_url + encodeURIComponent(page_url), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    shareOnWhatsapp = () => {
        if (serverResponse.config.ga_active) {
            ReactGA.event({
                category: serverResponse.config.release_id,
                action: gaEventLabel.share_product_whatsapp,
                label: String(this.props.info.item_id)
            });
        }
        // let imageSrc = serverResponse.config.serverPath + this.props.info.images[this.props.info.images.length - 1].image_file;
        // //window.location = "http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc);
        // window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(imageSrc), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        let page_url = productUrl + "/share/product.php?c=" + this.props.info.item_id;
        if (window.ReactNativeWebView != undefined && isIOS) {
            let link = encodeURIComponent(page_url);
            let title = serverResponse.config.shareProductSentence;
            var str = title + ' ' + link;
            var obj = { action: "whatsapp", value: str };
            window.ReactNativeWebView.postMessage(JSON.stringify(obj));
        } else {
            window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + ' ' + encodeURIComponent(page_url), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        }
        // window.open("http://api.whatsapp.com/send?text=" + serverResponse.config.shareProductSentence + '    ' + encodeURIComponent(page_url), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    toggleSaveTooltip = () => {
        this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
    }

    handleRelatedClick = (item_id) => {
        this.setModal(true);
        let marker = document.getElementById("plus-" + item_id);
        marker.click();
    }

    render() {

        const markers = this.props.info.markers.map((element, key) => {
            switch (element.type) {
                case "plus": {
                    return null;
                }
                case "info": {
                    return <img key={key} src={InfoIcon} className="icon-marker" alt="Info" onClick={() => {
                        this.props.navMarkers("info");
                        this.setModal(false);
                        if (serverResponse.config.ga_active) {
                            ReactGA.event({
                                category: serverResponse.config.release_id,
                                action: gaEventLabel.open_product_curiosity,
                                label: String(this.props.info.item_id)
                            });
                        }
                    }} />
                }
                case "world": {
                    return <img key={key} src={WorldIcon} className="icon-marker" alt="World" onClick={() => {
                        this.props.navMarkers("world");
                        this.setModal(false);
                        ReactGA.event({
                            category: serverResponse.config.release_id,
                            action: gaEventLabel.open_product_link,
                            label: String(this.props.info.item_id)
                        });
                    }} />
                }
                case "hat-chef": {
                    return <img key={key} src={HatChefIcon} className="icon-marker" alt="World" onClick={() => {
                        this.props.navMarkers("hatchef");
                        this.setModal(false);
                        if (serverResponse.config.ga_active) {
                            ReactGA.event({
                                category: serverResponse.config.release_id,
                                action: gaEventLabel.open_product_recipe,
                                label: String(this.props.info.item_id)
                            });
                        }
                    }} />
                }
                case "play": {
                    return <img key={key} src={PlayIcon} className="icon-marker" alt="World" onClick={() => {
                        this.props.navMarkers("play");
                        this.setModal(false);
                        if (serverResponse.config.ga_active) {
                            ReactGA.event({
                                category: serverResponse.config.release_id,
                                action: gaEventLabel.open_product_video,
                                label: String(this.props.info.item_id)
                            });
                        }
                    }} />
                }
                case "specs": {
                    return <img key={key} src={SpecsIcon} className="icon-marker" alt="World" onClick={() => {
                        this.props.navMarkers("specs");
                        this.setModal(false);
                        if (serverResponse.config.ga_active) {
                            ReactGA.event({
                                category: serverResponse.config.release_id,
                                action: gaEventLabel.open_product_specifications,
                                label: String(this.props.info.item_id)
                            });
                        }
                    }} />
                }
                case "promo": {
                    return <img key={key} src={PromoIcon} className="icon-marker-promo" alt="World" onClick={() => {
                        this.props.navMarkers("promo");
                        this.setModal(false);
                        if (serverResponse.config.ga_active) {
                            ReactGA.event({
                                category: serverResponse.config.release_id,
                                action: gaEventLabel.open_promo
                            });
                        }
                    }} />
                }
                default: return null
            }
        });

        const listImages = this.props.info.images.map((element, key) =>
            <img key={key} src={process.env.PUBLIC_URL + element.image_file} alt="img" />
        );

        let varieties = "";
        let mobileVarieties = "";
        if (this.props.info.varieties) {
            if (this.props.info.varieties.length > 0) {
                varieties = <Row style={{ marginTop: "30px" }}>
                    <Col md="12">
                        <Label for="variety">Scegli:</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>
                            <Input type="select" name="select" id="variety" value={this.state.varietySelected} onChange={this.handleChangeVariety}>
                                {
                                    this.props.info.varieties.map((element, key) => {
                                        return <option key={key} value={element}>{element}</option>
                                    })
                                }
                            </Input>
                        </InputGroup>
                    </Col>
                </Row>;
                mobileVarieties = <Col xs="12" style={{ marginBottom: "10px" }}>
                    <Label for="variety">Scegli:</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend"><span className="input-group-text icon-input-group"><FontAwesomeIcon icon={faBars} /></span></InputGroupAddon>
                        <Input type="select" name="select" id="variety" value={this.state.varietySelected} onChange={this.handleChangeVariety}>
                            {
                                this.props.info.varieties.map((element, key) => {
                                    return <option key={key} value={element}>{element}</option>
                                })
                            }
                        </Input>
                    </InputGroup>
                </Col>;
            }
        }

        let brandImg = "";
        if (serverResponse.config.brandImage !== "" && this.props.info.pam === true) {
            brandImg = <img style={{ width: "15%" }} src={process.env.PUBLIC_URL + serverResponse.config.brandImage} alt="img" />
        }

        let cardImg = "";
        if (serverResponse.config.underPriceImage !== "" && this.props.info.underpriced_product === true) {
            cardImg = <img style={{ width: "50%", marginTop: "10px" }} src={process.env.PUBLIC_URL + serverResponse.config.underPriceImage} alt="img" />;
        }

        let related = [];
        if (this.props.info.related) {
            this.props.info.related.map(element => {
                this.state.products.map((prod, key) => {
                    if (prod.item_id === element) {
                        related.push(
                            <RelatedProduct key={key} prod={prod} handleClick={this.handleRelatedClick} />
                        );
                    }
                })
            })
        }

        let responsive = {
            0: { items: 5 },
            1024: { items: 5 },
        }

        var closeBtn = <Button color="secondary" onClick={() => this.setModal(true)} ><i className="fas fa-arrow-left"></i><strong> INDIETRO</strong></Button>

        return (
            <div className="marker vertical" type="plus" style={{ display: this.props.info.hidden_price ? "none" : "" }}>
                <img id={"plus-" + this.props.info.item_id} src={Logo} className="icon-marker" alt="Plus" onClick={() => {
                    this.setModal(false);
                    if (serverResponse.config.ga_active) {
                        ReactGA.event({
                            category: serverResponse.config.release_id,
                            action: gaEventLabel.open_product,
                            label: String(this.props.info.item_id)
                        });
                    }
                }} />
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.setModal(true)}
                    size="lg"
                >
                    <ModalHeader toggle={() => this.setModal(true)} close={closeBtn}>
                        Dettaglio
                    </ModalHeader>

                    {/*Desktop version*/}
                    <ModalBody className="modal-plus-desktop">
                        <Row style={{ marginBottom: "16px" }}>
                            <Col style={{ display: "flex", height: "30px" }}>
                                <div className="markers-container" style={{ width: "100%" }}>
                                    <div className="vertical">{markers}</div>
                                    <img src={Facebook} className="share-icon" style={{ marginLeft: "auto" }} alt="facebook" onClick={() => this.shareOnFacebook()} />
                                    <img src={Whatsapp} className="share-icon" alt="whatsapp" onClick={() => this.shareOnWhatsapp()} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col id="modalCarousel" md="6">
                                <Carousel
                                    swipeable={true}
                                    emulateTouch={true}
                                    infiniteLoop={true}
                                    showStatus={false}>

                                    {listImages}

                                </Carousel>
                            </Col>
                            <Col md="6">
                                <Textfit mode="multi" min={30}>
                                    <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>{this.props.info.field1}</span>
                                </Textfit>
                                <Textfit mode="multi" max={15}>
                                    <span style={{ textTransform: "uppercase" }}>{this.props.info.field2}</span>
                                </Textfit>
                                <h6 style={{ textTransform: "lowercase" }}>{this.props.info.field3}</h6>
                                <h6 style={{ textTransform: "lowercase" }}>{this.props.info.field4}</h6>
                                {brandImg}
                                {cardImg}

                                <Row style={{ marginTop: "30px" }}>
                                    <Col md="12" className="product-price">
                                        <h3 style={{ fontWeight: "bold" }}><span style={{ fontSize: "3rem" }}>{this.props.info.price_label}</span></h3>
                                    </Col>
                                </Row>
                                {varieties}

                                <Row style={{ marginTop: "30px" }}>
                                    <Col md="12">
                                        <div className="container-add-to-list" style={{ justifyContent: "space-between" }}>
                                            {!this.props.info.one_and_one_gratis &&
                                                <div className="container-add-to-list">
                                                    <FontAwesomeIcon className="change-product-quantity-icon" icon={faMinusCircle} onClick={() => this.decrementQty()} />
                                                    <h6>{this.state.quantity}</h6>
                                                    <h6 style={{ fontWeight: "bold" }}>{this.props.info.grocery_label}</h6>
                                                    <FontAwesomeIcon className="change-product-quantity-icon" icon={faPlusCircle} onClick={() => this.incrementQty()} />
                                                </div>}
                                            {!this.props.info.one_and_one_gratis &&
                                                <div className="container-add-to-list">
                                                    <Button id={"btnSave_" + this.props.info.item_id} color="primary" onClick={() => {
                                                        this.handleAddProduct();
                                                        ReactGA.event({
                                                            category: serverResponse.config.release_id,
                                                            action: gaEventLabel.add_to_grocery_list,
                                                            label: String(this.props.info.item_id)
                                                        });
                                                    }}>
                                                        {/*<FontAwesomeIcon icon={faShoppingBasket} />*/}
                                                        <img src={AddProd} style={{ color: "#00ff00", width: "30px", cursor: "pointer" }} alt="img" />

                                                    </Button>
                                                    <Tooltip placement="top" isOpen={this.state.saveTooltipOpen} target={"btnSave_" + this.props.info.item_id} toggle={this.toggleSaveTooltip}>
                                                        {strings.saveBtnTooltip}
                                                    </Tooltip>
                                                </div>}
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>{related.length > 0 ? strings.relatedProductsTitle : ""}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <div className="related_products_container" style={{
                                    width: "100%",
                                    height: "200px",
                                    paddingLeft: "3%",
                                    paddingRight: "3%",
                                    display: related.length > 0 ? "flex" : "none",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "white"
                                }}>


                                    <div className="">
                                        <FontAwesomeIcon icon={faAngleLeft} size="2x" onClick={() => this.DesktopCarousel.slidePrev()} className="search-carousel-icon" />
                                    </div>

                                    <AliceCarousel
                                        items={related}
                                        responsive={responsive}
                                        autoPlay={false}
                                        fadeOutAnimation={true}
                                        playButtonEnabled={false}
                                        disableAutoPlayOnAction={true}
                                        dotsDisabled={true}
                                        buttonsDisabled={true}
                                        ref={(el) => (this.DesktopCarousel = el)}
                                        infinite={false}
                                        mouseDragEnabled={false}
                                    />
                                    <div className="">
                                        <FontAwesomeIcon icon={faAngleRight} size="2x" onClick={() => this.DesktopCarousel.slideNext()} className="search-carousel-icon" />
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </ModalBody>

                    {/*Mobile version*/}
                    <ModalBody className="modal-plus-mobile">
                        <Row style={{ marginBottom: "16px" }}>
                            <Col style={{ /*display: "flex"*/ }}>
                                <div className="markers-container" style={{ width: "100%" }}>
                                    <div className="vertical">{markers}</div>
                                    <img src={Facebook} className="share-icon" style={{ marginLeft: "auto" }} alt="facebook" onClick={() => this.shareOnFacebook()} />
                                    <img src={Whatsapp} className="share-icon" alt="whatsapp" onClick={() => this.shareOnWhatsapp()} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col id="modalCarousel" style={{ marginBottom: "10px", width: '100%' }}>
                                <Carousel
                                    swipeable={true}
                                    emulateTouch={true}
                                    infiniteLoop={true}
                                    showStatus={false}>

                                    {listImages}

                                </Carousel>
                            </Col>
                            <Col style={{ marginBottom: "10px" }}>
                                <Textfit mode="multi" min={30}>
                                    <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>{this.props.info.field1}</span>
                                </Textfit>
                                <Textfit mode="multi" max={15}>
                                    <span style={{ textTransform: "uppercase" }}>{this.props.info.field2}</span>
                                </Textfit>
                                <h6 style={{ textTransform: "lowercase" }}>{this.props.info.field3}</h6>
                                <h6 class={serverResponse.config.line_through === true ? "barrato" : null} style={{ textTransform: "lowercase" }}>{this.props.info.field4}</h6>
                                {brandImg}
                                {cardImg}
                            </Col>
                            <Col xs="12" className="product-price" style={{ marginBottom: "10px" }}>
                                <h3 style={{ fontWeight: "bold" }}><span style={{ fontSize: "3rem" }}>{this.props.info.price_label}</span></h3>
                            </Col>
                            {mobileVarieties}
                            <Col xs="12">
                                <div className="container-add-to-list" style={{ justifyContent: "space-between" }}>
                                    {!this.props.info.one_and_one_gratis &&
                                        <div className="container-add-to-list">
                                            <FontAwesomeIcon style={{ color: "var(--primary-color)" }} icon={faMinusCircle} onClick={() => this.decrementQty()} />
                                            <h6>{this.state.quantity}</h6>
                                            <h6 style={{ fontWeight: "bold" }}>{this.props.info.grocery_label}</h6>
                                            <FontAwesomeIcon style={{ color: "var(--primary-color)" }} icon={faPlusCircle} onClick={() => this.incrementQty()} />
                                        </div>
                                    }
                                    {!this.props.info.one_and_one_gratis &&
                                        <div className="container-add-to-list">
                                            <div className="text-center">
                                                <Button color="primary" onClick={() => {
                                                    this.handleAddProduct();
                                                    ReactGA.event({
                                                        category: serverResponse.config.release_id,
                                                        action: gaEventLabel.add_to_grocery_list,
                                                        label: String(this.props.info.item_id)
                                                    });
                                                }}>
                                                    {/*<FontAwesomeIcon icon={faShoppingBasket} />*/}
                                                    <img src={AddProd} style={{ color: "#00ff00", width: "30px", cursor: "pointer" }} alt="img" />
                                                </Button>
                                                <div className="aggiungiButtonText">Aggiungi</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                            {/* <Col style={{ marginBottom: "16px" }}>
                                <Carousel
                                    swipeable={true}
                                    emulateTouch={true}
                                    infiniteLoop={true}
                                    showStatus={false}>

                                    {listImages}

                                </Carousel>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <span>{related.length > 0 ? strings.relatedProductsTitle : ""}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>

                                <div className="related_products_container" style={{
                                    width: "100%",
                                    height: "200px",
                                    paddingLeft: "3%",
                                    paddingRight: "3%",
                                    display: related.length > 0 ? "flex" : "none",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "white"
                                }}>


                                    <div className="">
                                        <FontAwesomeIcon icon={faAngleLeft} size="2x" onClick={() => this.Carousel.slidePrev()} className="search-carousel-icon" />
                                    </div>

                                    <AliceCarousel
                                        items={related}
                                        responsive={responsive}
                                        autoPlay={false}
                                        fadeOutAnimation={true}
                                        playButtonEnabled={false}
                                        disableAutoPlayOnAction={true}
                                        dotsDisabled={true}
                                        buttonsDisabled={true}
                                        ref={(el) => (this.Carousel = el)}
                                        infinite={false}
                                        mouseDragEnabled={false}
                                    />
                                    <div className="">
                                        <FontAwesomeIcon icon={faAngleRight} size="2x" onClick={() => this.Carousel.slideNext()} className="search-carousel-icon" />
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={() => this.setModal(true)}>
                            Chiudi
                        </Button>
                    </ModalFooter>
                </Modal>
            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addProduct: (newProduct) => {
            dispatch(actions.addProduct(newProduct));
        },
    }
}

export default connect(null, mapDispatchToProps)(PlusMarker);